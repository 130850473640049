<template>
  <!-- <v-app>
    <NavDrawer />
    <v-main
      :class="$vuetify.theme.dark ? '' : 'grey lighten-5'"
      style="padding-bottom:100px"
    >
      <v-container>
        <router-view />
      </v-container>
      <LoadingOverlay />
  
      <v-slide-x-reverse-transition>
        <v-snackbar
          :value="$store.state.snackbar.display"
          :color="$store.state.snackbar.color"
          right
          rounded="pill"
          top
          style="z-index: 10"
        >
          {{ $store.state.snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              @click="$store.state.snackbar.display = false"
              class="mr-4"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-slide-x-reverse-transition>
      <AuthDialog />
    </v-main>
    <Footer />
  </v-app> -->

  <v-app>
    <!-- rebuildingSoon -->
    <v-system-bar
      app
      height="25"
      dark
      :color="rebuildingSoon.usersReady ? 'green' : 'red'"
      v-if="devMode || rebuildingSoon.value"
    >
      <v-spacer />
      <div
        class="font-weight-bold body-1 white--text"
        :class="{ pulse: rebuildingSoon.value && !rebuildingSoon.usersReady }"
      >
        {{
          devMode
            ? "Dev Mode Active"
            : "Admin Portal Rebuilding Soon. Please save your work. This notice will disappear when the rebuild is complete."
        }}
      </div>
      <v-spacer />
      <div
        class="font-weight-bold body-1 white--text"
        v-if="!rebuildingSoon.usersReady"
      >
        <v-checkbox
          label="Click here if ready"
          v-model="rebuildingSoon.usersReady"
          @change="setUsersReady"
        ></v-checkbox>
      </div>
    </v-system-bar>

    <NavDrawer />
    <v-app-bar app flat color="grey darken-3" dark dense>
      <v-app-bar-nav-icon
        @click="$store.state.navDrawer = !$store.state.navDrawer"
      />
      <!-- <v-spacer />
      <AlgoliaSearch class="flex-grow-1" /> -->
      <v-spacer />
      <div class="font-weight-medium">{{ today }}</div>
    </v-app-bar>

    <v-main
      :class="$vuetify.theme.dark ? '' : 'grey lighten-5'"
      style="padding-bottom:100px"
    >
      <v-container>
        <router-view />
      </v-container>
      <LoadingOverlay />
      <v-slide-x-reverse-transition>
        <v-snackbar
          :value="$store.state.snackbar.display"
          :color="$store.state.snackbar.color"
          right
          rounded="pill"
          top
          style="z-index: 10"
        >
          {{ $store.state.snackbar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              @click="$store.state.snackbar.display = false"
              class="mr-4"
            >
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-slide-x-reverse-transition>
      <AuthDialog />
    </v-main>
  </v-app>
</template>

<script>
import NavDrawer from "@/components/NavDrawer";
import AuthDialog from "@/components/AuthDialog";
import LoadingOverlay from "@/components/LoadingOverlay";
// import AlgoliaSearch from "@/components/AlgoliaSearch";
import watchAuth from "@/mixins/watchAuth";
import { portalsApp } from "@/firebase";
import moment from "moment";
export default {
  mixins: [watchAuth],
  components: {
    NavDrawer,
    AuthDialog,
    LoadingOverlay,
    // AlgoliaSearch,
  },
  async mounted() {
    portalsApp.auth().onAuthStateChanged(async (user) => {
      const role = await portalsApp
        .firestore()
        .collection("staff")
        .where("email", "==", user.email);
      await this.$bind("role", role);
      const userRole = await this.$bind("role", role);
      this.$store.state.isSuperAdmin = userRole[0].role == "Super Admin";
    });
    this.$bind(
      "rebuildingSoon",
      portalsApp
        .firestore()
        .collection("admin")
        .doc("rebuildingSoon")
    ).then((data) => {
      this.rebuildingSoon = data;
    });
  },
  data: () => ({
    rebuildingSoon: { value: false, usersReady: false },
  }),
  methods: {
    setUsersReady() {
      portalsApp
        .firestore()
        .collection("admin")
        .doc("rebuildingSoon")
        .update({
          usersReady: this.rebuildingSoon.usersReady,
        });
    },
  },
  computed: {
    today() {
      return moment().format("dddd MMM DD, YYYY");
    },
    devMode() {
      if (process.env.NODE_ENV == undefined) return false;
      if (process.env.NODE_ENV == null) return false;
      if (process.env.NODE_ENV.trim() == "development") return false;
      return false;
    },
  },
  watch: {
    "$store.state.snackbar.display"() {
      setTimeout(() => {
        this.$store.state.snackbar.display = false;
      }, 5500);
    },
    "$store.state.user": {
      immediate: true,
      async handler(user) {
        if (!user || user == null || user == undefined) return;
        await this.$store.dispatch("bindEventReports");
      },
    },
  },
};
</script>

<style>
.pulse {
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

/* global styles */
a {
  text-decoration: none;
}

.v-card {
  width: 100% !important;
}

/* snackbar */
.v-snack__content {
  font-weight: 500 !important;
}

/* removing up and down arrows in number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* custom scrollbars */
/* ::-webkit-scrollbar,
.scrollBox::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.65rem;
}
::-webkit-scrollbar-track,
.scrollBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb,
.scrollBox::-webkit-scrollbar-thumb {
  background-color: #1976d2;
  border-radius: 2px;
} */

/* page header w/list */
.pageHeaderListWrapper {
  width: 200px !important;
  text-align: left !important;
  align-self: center !important;
  align-items: flex-start !important;
}

/* utility classes */
.border {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.italic {
  font-style: italic;
}
.text-sm {
  font-size: 14px;
}

.sticky-v-card-actions {
  position: sticky !important;
  left: 0 !important;
  bottom: 0 !important;
  background: #f2f2f2 !important;
  border-top: 1px solid #e1e1e1 !important;
  width: 100% !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.toolbarSearch {
  padding-top: 3px !important;
  margin-bottom: 1px !important;
  background: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

.Vue-Toastification__toast {
  font-family: "Roboto", sans-serif !important;
}

#nprogress .bar {
  background: #e91e63 !important;
  height: 4px !important;
  z-index: 99999 !important;
}

#nprogress .spinner-icon {
  border-top-color: #e91e63 !important;
  border-left-color: #e91e63 !important;
}

.h-full {
  height: 100%;
}

.grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.gap-2 {
  gap: 0.5rem; /* 8px */
}

.gap-4 {
  gap: 1rem; /* 16px */
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-initial {
  flex: 0 1 auto;
}

.grow {
  flex-grow: 1;
}
</style>
