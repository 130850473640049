<template>
  <!-- Reserve funds calculation -->
  <v-col cols="4" sm="6" md="4" class="d-flex">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary text-center">
        <p>
          <v-icon color="primary" class="display-1">mdi-currency-usd</v-icon>
        </p>
        <h3 class="title">Reserve Funds Calculation</h3>
        <p class="title mt-3">{{ reserveFunds }}</p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import NProgress from "nprogress";
import formatCurrency from "@/mixins/formatCurrency";
import moment from 'moment'
import { mainApp, portalsApp } from "../firebase";
const bookingsConfirmations = portalsApp
  .firestore()
  .collection("bookingsConfirmations")
  .orderBy("dateSelectedForBooking", "desc");
const packages = mainApp
  .firestore()
  .collection("packages")
  .where("available", "==", true);
export default {
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.$bind("bookingsConfirmations", bookingsConfirmations);
    await this.$bind("packages", packages);
     // bind to mps items
    await this.$bind(
      "mps",
      portalsApp.firestore().collection("mps")
    );
    await this.$store.dispatch("setLoading", false);
  },
  mixins: [formatCurrency],
  data: () => ({
    loading: false,
    bookingsConfirmations: [],
    packages: [],
    mps:[],
  }),
  computed: {
    //All Currently Active Packages (Not Traveled/Booked & Not Expired)
    activePackages() {
      // const packages = this.packages.filter(
      //   (el) =>
      //     !this.bookingsConfirmations.some(
      //       (booking) => booking.packageCode === el.id
      //     )
      // );
      // return packages.length;
      const activeBookings = this.mps.filter(el=>  {
        const today = moment();
        if( el.originalExpiryDate && el.originalExpiryDate != 'VOID'){
          const expiryDate = moment(el.originalExpiryDate);
          if(expiryDate.isSameOrAfter(today, 'day')) return el
        }else{
          if(el.travelDate && el.travelDate != 'VOID'){
             const travelDate = moment(el.travelDate);
            if(travelDate.isSameOrAfter(today, 'day')) return el
          }
        }
        
      })
      return activeBookings.length;
    },
    //Last 200 Booked Travelers
    last200Booked() {
      // Calculate the date one month ago from the current date
      const oneMonthAgo = moment().subtract(1, 'months');

      // Filter the array to get records from the last one month
      const filteredBookings = this.bookingsConfirmations.filter(booking => {
        const date = moment(booking.dateSelectedForBooking, 'YYYY-MM-DD');
        return date.isSameOrAfter(oneMonthAgo);
      })
      
      return filteredBookings;
      // return this.bookingsConfirmations.slice(0, 200);
    },
    reserveFunds() {
      // Average Cost of the Last 200 Booked Travelers
      let c =
        this.last200Booked.reduce((accumulator, currentValue) => {
          if(currentValue.actualRoomCost){
          if (currentValue.actualRoomCost && currentValue.actualRoomCost !== "-")
            return accumulator + currentValue.actualRoomCost * 1;
          }else{
            if (currentValue.roomCost && currentValue.roomCost !== "-")
            return accumulator + currentValue.roomCost * 1;
          }
          return accumulator;
        }, 0) / 200;

      //Average Upgrade of the Last 200 Booked Travelers
      let u =
        this.last200Booked.reduce((accumulator, currentValue) => {
          if (
            currentValue.actualUpgradeSurcharge &&
            currentValue.actualUpgradeSurcharge !== "-"
          )
            return accumulator + currentValue.actualUpgradeSurcharge * 1;
          return accumulator;
        }, 0) / 200;

      //Formula :- (C - U) x A = R
      let totalCalculatedValue = ((c - u) * this.activePackages).toFixed(2);
      totalCalculatedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCalculatedValue)
      return totalCalculatedValue;
    },
  },
};
</script>
