<template>
  <!-- last ac campaign sent -->
  <v-col cols="12" sm="8">
    <v-card outlined :loading="loading">
      <v-card-text class="text--primary" v-if="$store.state.lastAcCampaignSent">
        <p class="d-flex">
          <v-icon color="primary" class="display-1 mr-6 mt-n1"
            >mdi-email-outline</v-icon
          >
          <span class="title">Last AC Campaign Data</span>
        </p>
        <p class="title mt-3">
          Date Sent: {{ formatDate($store.state.lastAcCampaignSent.sdate) }}
        </p>
        <p class="title mt-3">
          Subject: {{ $store.state.lastAcCampaignSent.name }}
        </p>
        <p class="title mt-3">
          Sent To: {{ $store.state.lastAcCampaignSent.send_amt }}
        </p>
        <p class="title mt-3">
          Opens: {{ $store.state.lastAcCampaignSent.uniqueopens }}
        </p>
        <p class="title mt-3">
          Clicks: {{ $store.state.lastAcCampaignSent.subscriberclicks }}
        </p>
        <p class="title mt-3 mb-0">
          Unsubscribes: {{ $store.state.lastAcCampaignSent.unsubscribes }}
        </p>
      </v-card-text>
      <v-card-actions v-if="$store.state.lastAcCampaignSent">
        <v-btn
          small
          text
          color="primary"
          :href="
            `https://silentauctionvacations.activehosted.com/report/#/campaign/${$store.state.lastAcCampaignSent.id}/overview`
          "
          target="_blank"
        >
          Open Campaign Report <v-icon small right>mdi-open-in-new</v-icon>
        </v-btn>
        <v-spacer />

        <v-btn
          small
          text
          color="primary"
          href="https://silentauctionvacations.activehosted.com/overview/"
          target="_blank"
        >
          Open AC Dashboard<v-icon small right>mdi-open-in-new</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
import axios from "axios";
import formatDate from "@/mixins/formatDate";
import NProgress from "nprogress";
export default {
  mixins: [formatDate],
  data: () => ({
    loading: false,
  }),
  methods: {
    async getLastAcCampaignSent() {
      if (this.$store.state.lastAcCampaignSent != null) return;

      return new Promise(async (resolve, reject) => {
        this.loading = true;
        NProgress.start();

        try {
          let req = await axios.get(
            "https://9awjtqtr37.execute-api.us-east-1.amazonaws.com/default/getMostRecentActiveCampaignEmailSentLambda"
          );

          if (!req.data.error && req.data.data)
            resolve((this.$store.state.lastAcCampaignSent = req.data.data));

          this.loading = false;
          NProgress.done();
        } catch (error) {
          console.log(error);
          this.$toast.error("Error: getLastAcCampaignSent()");
          this.loading = false;
          NProgress.done();
          reject(error);
        }
      });
    },
  },
  watch: {
    "$store.state.user": {
      immediate: true,
      async handler(user) {
        if (!user || user == null || user == undefined) return;
        if (user) return await this.getLastAcCampaignSent();
      },
    },
  },
};
</script>
