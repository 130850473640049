import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import NProgress from "nprogress";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/temp",
    component: function() {
      return import("../views/temp.vue");
    },
  },
  {
    path: "/inventory",
    component: function() {
      return import("../views/Inventory/Index.vue");
    },
  },
  {
    path: "/inventory/new",
    component: function() {
      return import("../views/Inventory/New.vue");
    },
  },
  {
    path: "/inventory/traveler-view",
    component: function() {
      return import("../views/Inventory/traveler-view.vue");
    },
  },
  {
    path: "/inventory/sitemaps",
    component: function() {
      return import("../views/Inventory/sitemaps.vue");
    },
  },
  {
    path: "/inventory/scrape",
    component: function() {
      return import("../views/Inventory/scrape.vue");
    },
  },
  {
    path: "/inventory/scrapelistings",
    component: function() {
      return import("../views/Inventory/scrapelisting.vue");
    },
  },
  {
    path: "/inventory/upload",
    component: function() {
      return import("../views/Inventory/upload.vue");
    },
  },
  {
    path: "/inventory/add-property",
    component: function() {
      return import("../views/Inventory/add-property.vue");
    },
  },
  // keep /:id at end of inventory routes to preserve above pages
  {
    params: true,
    path: "/inventory/:id",
    component: function() {
      return import("../views/Inventory/_id.vue");
    },
  },

  {
    path: "/event-reports",
    component: function() {
      return import("../views/EventReports.vue");
    },
  },
  // {
  //   path: "/event-reports/new",
  //   component: function() {
  //     return import("../views/EventReports/new.vue");
  //   },
  // },
  // {
  //   params: true,
  //   path: "/event-reports/:id",
  //   component: function() {
  //     return import("../views/EventReports/_id.vue");
  //   },
  // },
  {
    path: "/users",
    component: function() {
      return import("../views/Users/Index.vue");
    },
  },
  {
    params: true,
    path: "/users/:id",
    component: function() {
      return import("../views/Users/_id.vue");
    },
  },
  {
    path: "/packages",
    component: function() {
      return import("../views/Packages/Index.vue");
    },
  },
  {
    path: "/packages/categories",
    component: function() {
      return import("../views/Packages/Categories/Index.vue");
    },
  },
  {
    path: "/alternative-destinations",
    component: function() {
      return import("../views/AlternativeDestinations/Index.vue");
    },
  },
  {
    params: true,
    path: "/alternative-destinations/:id",
    component: function() {
      return import("../views/AlternativeDestinations/_id.vue");
    },
  },
  {
    params: true,
    path: "/packages/categories/:id",
    component: function() {
      return import("../views/Packages/Categories/_id.vue");
    },
  },
  {
    path: "/packages/roomtypes",
    component: function() {
      return import("../views/Packages/RoomTypes/Index.vue");
    },
  },
  {
    params: true,
    path: "/packages/roomtypes/:id",
    component: function() {
      return import("../views/Packages/RoomTypes/_id.vue");
    },
  },
  {
    path: "/packages/numbernights",
    component: function() {
      return import("../views/Packages/NumberNights/Index.vue");
    },
  },
  {
    params: true,
    path: "/packages/numbernights/:id",
    component: function() {
      return import("../views/Packages/NumberNights/_id.vue");
    },
  },
  {
    path: "/packages/terms",
    component: function() {
      return import("../views/Terms.vue");
    },
  },
  // {
  //   path: '/packages/terms',
  //   component: function () {
  //     return import('../views/Packages/Terms/Index.vue')
  //   },
  // },
  // {
  //   params: true,
  //   path: '/packages/terms/:id',
  //   component: function () {
  //     return import('../views/Packages/Terms/_id.vue')
  //   },
  // },
  {
    path: "/messages",
    component: function() {
      return import("../views/Messages/Index.vue");
    },
  },
  {
    params: true,
    path: "/messages/:id",
    component: function() {
      return import("../views/Messages/_id.vue");
    },
  },
  {
    params: true,
    path: "/packages/new",
    component: function() {
      return import("../views/Packages/New.vue");
    },
  },
  {
    params: true,
    path: "/packages/:id",
    component: function() {
      return import("../views/Packages/_id.vue");
    },
  },
  {
    path: "/bookings",
    component: function() {
      return import("../views/Bookings/index.vue");
    },
  },
  {
    path: "/bookings/:id",
    component: function() {
      return import("../views/Bookings/_id.vue");
    },
  },
  {
    path: "/av-sheets",
    component: function() {
      return import("../views/AV-Sheets/index.vue");
    },
  },
  {
    path: "/av-sheets/:id",
    component: function() {
      return import("../views/AV-Sheets/_id.vue");
    },
  },
  {
    path: "/admin",
    component: function() {
      return import("../views/Admin/index.vue");
    },
  },
  {
    path: "/mps",
    component: function() {
      return import("../views/MPS.vue");
    },
  },
  {
    path: "/auctioneers",
    component: function() {
      return import("../views/Auctioneers.vue");
    },
  },
  {
    path: "/credits",
    component: function() {
      return import("../views/Credits.vue");
    },
  },
  {
    path: "/standardoperatingprocedures",
    component: function() {
      return import("../views/StandardOperatingProcedures.vue");
    },
  },
  // {
  //   params: true,
  //   path: "/new-event-reports",
  //   component: function() {
  //     return import("@/views/NewEventReports.vue");
  //   },
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   return { top: 0, left: 0 };
  // },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
