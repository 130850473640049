import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// portals app
const firebasePortalsConfig = {
  apiKey: "AIzaSyA8kajme0XJ32Vet_71EJ13S6vCGR0PUcY",
  authDomain: "sav-traveler-portal.firebaseapp.com",
  databaseURL: "https://sav-traveler-portal.firebaseio.com",
  projectId: "sav-traveler-portal",
  storageBucket: "sav-traveler-portal.appspot.com",
  messagingSenderId: "134221011062",
  appId: "1:134221011062:web:095b1b414c8e8c6e48e757",
};

const portalsApp = firebase.initializeApp(firebasePortalsConfig);

// main app
const firebaseMainConfig = {
  apiKey: "AIzaSyCP6yMrCtDWNbMgUwdLyeO3BDRAJwGCNYk",
  authDomain: "silent-auction-vacations.firebaseapp.com",
  databaseURL: "https://silent-auction-vacations.firebaseio.com",
  projectId: "silent-auction-vacations",
  storageBucket: "silent-auction-vacations.appspot.com",
  messagingSenderId: "1044426753281",
  appId: "1:1044426753281:web:467f396e93c5c413c60700",
};

const mainApp = firebase.initializeApp(firebaseMainConfig, "secondary");

export { mainApp, portalsApp };
