import { portalsApp } from "@/firebase";

export default {
  beforeMount() {
    portalsApp.auth().onAuthStateChanged((user) => {
      // invalid user
      if (user == null || user == undefined)
        return (this.$store.state.authDialog = true);

      // user not admin
      if (user && user.uid != "C5aZfNEMMoNraA9JWz6hZ6gOdUe2")
        return this.signOut();

      // user valid => set user in store
      return (this.$store.state.user = user);
    });
  },
};
